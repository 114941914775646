<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" :title="formTitle">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="租户管理员帐号" prop="adminusername" v-if="formType === 1">
        <a-input v-model="form.adminusername" placeholder="请输入租户管理员帐号" />
      </a-form-model-item>
      <a-form-model-item label="租户管理员密码" prop="adminpassword" v-if="formType === 1">
        <a-input-password v-model="form.adminpassword" placeholder="请输入租户管理员密码" />
      </a-form-model-item>
      <a-form-model-item label="租户名称" prop="name">
        <a-input v-model="form.name" placeholder="请输入租户名称" />
      </a-form-model-item>
      <a-form-model-item label="租户代码" prop="uniqueCode">
        <a-input v-model="form.uniqueCode" placeholder="请输入租户代码" :disabled="this.formType === 2" />
      </a-form-model-item>
      <a-form-model-item label="中心点经度" prop="lng" >
        <a-input v-model="form.lng" placeholder="请输入经度" />
      </a-form-model-item>
      <a-form-model-item label="中心点纬度" prop="lat" >
        <a-input v-model="form.lat" placeholder="请输入纬度" />
      </a-form-model-item>
      <a-form-model-item label="公司地址" prop="companyAddress" >
        <a-input v-model="form.companyAddress" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="公司区域" prop="companyArea" >
        <a-input v-model="form.companyArea" placeholder="请输入公司区域" />
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="mail" >
        <a-input v-model="form.mail" placeholder="请输入邮箱" />
      </a-form-model-item>
      <a-form-model-item label="手机" prop="phone" >
        <a-input v-model="form.phone" placeholder="请输入手机" />
      </a-form-model-item>
      <a-form-model-item label="法人代表" prop="legalRepresentative" >
        <a-input v-model="form.legalRepresentative" placeholder="请输入法人代表" />
      </a-form-model-item>
      <a-form-model-item label="状态：1正常，2停用" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="Number(d.dictValue)">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTenant, addTenant, updateTenant } from '@/api/system/tenant'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        adminusername: null,
        adminpassword: null,
        name: null,
        uniqueCode: null,
        lng: null,
        lat: null,
        companyAddress: null,
        companyArea: null,
        mail: null,
        phone: null,
        legalRepresentative: null,
        expirationDate: null,
        status: 1
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '租户名称不能为空', trigger: 'blur' }
        ],
        mail: [
          { required: true, message: '邮箱不能为空', trigger: 'blur' },
          {
            type: 'email',
            message: '请正确填写邮箱地址',
            trigger: ['blur', 'change']
          }
        ],
        phone: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          {
            pattern: /^1\d{10}$/,
            message: '请正确填写手机号',
            trigger: 'blur'
          }
        ],
        adminusername: [
          { required: true, message: '租户管理员帐号不能为空', trigger: 'blur' },
          {
            pattern: /^[0-9A-Za-z]{3,30}$/,
            message: '租户管理员帐号由字母或数字的组合，且为3到30位',
            trigger: 'blur'
          }
        ],
        adminpassword: [
          { required: true, message: '租户管理员密码不能为空', trigger: 'blur' }
        ],
        uniqueCode: [
          { required: true, message: '租户代码不能为空', trigger: 'blur' },
          {
            pattern: /^[0-9A-Za-z]{2,30}$/,
            message: '租户代码由字母或数字的组合，且为2到30位',
            trigger: 'blur'
          }
        ],
        lng: [
          {
            // eslint-disable-next-line
            pattern: /^[\-]?(\d{1,2}\.?\d*|1[0-7]?\d{1}\.?\d*|180\.?0*)$/,
            message: '请输入正确的经度数值',
            trigger: 'blur'
          }
        ],
        lat: [
          {
            // eslint-disable-next-line
            pattern: /^[\-]?([0-8]?\d{1}\.?\d*|90\.?0*)$/,
            message: '请输入正确的纬度数值',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        adminusername: null,
        adminpassword: null,
        name: null,
        uniqueCode: null,
        lng: null,
        lat: null,
        companyAddress: null,
        companyArea: null,
        mail: null,
        phone: null,
        legalRepresentative: null,
        expirationDate: null,
        status: 1
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTenant(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTenant(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addTenant(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
