var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.formTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "租户管理员帐号", prop: "adminusername" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入租户管理员帐号" },
                    model: {
                      value: _vm.form.adminusername,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "adminusername", $$v)
                      },
                      expression: "form.adminusername",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "租户管理员密码", prop: "adminpassword" } },
                [
                  _c("a-input-password", {
                    attrs: { placeholder: "请输入租户管理员密码" },
                    model: {
                      value: _vm.form.adminpassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "adminpassword", $$v)
                      },
                      expression: "form.adminpassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "租户名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入租户名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "租户代码", prop: "uniqueCode" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入租户代码",
                  disabled: this.formType === 2,
                },
                model: {
                  value: _vm.form.uniqueCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "uniqueCode", $$v)
                  },
                  expression: "form.uniqueCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "中心点经度", prop: "lng" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入经度" },
                model: {
                  value: _vm.form.lng,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lng", $$v)
                  },
                  expression: "form.lng",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "中心点纬度", prop: "lat" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入纬度" },
                model: {
                  value: _vm.form.lat,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lat", $$v)
                  },
                  expression: "form.lat",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "公司地址", prop: "companyAddress" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入内容",
                  type: "textarea",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.companyAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyAddress", $$v)
                  },
                  expression: "form.companyAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "公司区域", prop: "companyArea" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入公司区域" },
                model: {
                  value: _vm.form.companyArea,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyArea", $$v)
                  },
                  expression: "form.companyArea",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "邮箱", prop: "mail" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入邮箱" },
                model: {
                  value: _vm.form.mail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mail", $$v)
                  },
                  expression: "form.mail",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "手机", prop: "phone" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入手机" },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "法人代表", prop: "legalRepresentative" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入法人代表" },
                model: {
                  value: _vm.form.legalRepresentative,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "legalRepresentative", $$v)
                  },
                  expression: "form.legalRepresentative",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态：1正常，2停用", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: Number(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }